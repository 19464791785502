'use strict';
var debounce = require('lodash/debounce');

function validateCode(elm, submitButtonSelector) {
    const $this = $(elm);
    const zipCode = $this.val();
    const { maxLength } = elm;
    const { minLength } = elm;
    const regex = new RegExp(elm.pattern);
    const $submitButtonSelector = submitButtonSelector ? $(submitButtonSelector) : null;

    if ((zipCode.length < minLength || zipCode.length > maxLength) && !regex.test(zipCode)) {
        return;
    }

    const url = $this.data('url');
    const invalidFeedback = $this.parent().find('.invalid-feedback');

    $('.alert').remove();
    $.ajax({
        url,
        method: 'post',
        type: 'json',
        data: {
            zipCode,
            isMyArea: true,
        },
        success(data) {
            $this.removeClass('is-invalid');
            invalidFeedback.empty();
            // $('.js-button-add-address').removeAttr('disabled');
            if ($submitButtonSelector && $submitButtonSelector.length) {
                $(submitButtonSelector).removeAttr('disabled');
            }

            if (data.error) {
                invalidFeedback.append(data.message);
                $this.addClass('is-invalid');
            } else {
                $this.parent().append(`<div class="alert alert-info mx-auto mt-2">${data.message}</div>`);
            }
        },
        error(error) {
            $this.removeClass('is-invalid');
            invalidFeedback.empty();
            invalidFeedback.append(error);
        },
    });
}

module.exports = debounce(validateCode, 500);
