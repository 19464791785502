const formValidation = require('../components/formValidation');
const verifyZip = require('../components/verifyZip');

const cache = {
    $body: $('body'),
    $guestFormAddress: $('.js-guest-address-form'),
    $closeBanner: $('.js-close-banner'),
    $guestAddressModal: $('#newGuestAddressModal'),
    $newsletterContainer: $('.newsletter-container'),
    $newsletterBtn: $('.newsletter-btn'),
    $page : $('.page'),
    $navbarToggled : $('.js-navbar-toggled'),
    $header : $('#header'),
    $bannerDownloadApp : $('.banner-download-app'),
    $newletterCloseBtn: $('.newsletter-close'),
    $matomoEnabled: $('.matomoEnabled')
};

function showNewsletterDiv() {
    cache.$newsletterContainer.addClass('modifyLeft');
}

function hideNewsletterDiv() {
    cache.$newsletterContainer.removeClass('modifyLeft');
}


function clearControllerErrors() {
    const $controllerError = $('.controller-error');
    const $serverError = $('.server-error');

    if ($controllerError.length) {
        $controllerError.remove();
    }

    if ($serverError.length) {
        $serverError.remove();
    }
}

function submitFormHandler(e) {
    e.preventDefault();
    const form = $(this);

    const $modal = cache.$guestAddressModal;
    clearControllerErrors();

    $modal.spinner().start();

    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        data: form.serialize(),
        dataType: 'json',
        success(data) {
            if (data.success) {
                $modal.modal('hide');
                cache.$body.find('.js-address-name').empty().html(data.fullName);
                cache.$body.find('.js-address-street').empty().html(data.formattedStreet);
            } else {
                formValidation(form, data);
            }
            $modal.spinner().stop();
        },
        error(error) {
            $modal.spinner().stop();
            const errorEl = $.parseHTML(`<div class="invalid-feedback controller-error d-block mb-4">${error.responseJSON.message}</div>`)
            $modal.find('.modal-body').prepend(errorEl);
        },
    });
}

function hideBannerDownloadApp() {
    var removeBannerUrl = $(this).data('url');
    $.ajax({
        url: removeBannerUrl,
        type: 'post',
        success: function () {
            cache.$bannerDownloadApp.addClass('d-none');
            cache.$header.removeClass('mt-7 mt-0-xs');
            cache.$navbarToggled.removeClass('mt-7 mt-0-xs');
            cache.$page.removeClass('pt-10 pt-11-lg pt-6-xs pt-9-md');
        }
    });

}

function trackingViewPage() {
    var page_type = null;

    if(cache.$matomoEnabled.length) {
        if(cache.$matomoEnabled.find('.tracking-homepage').length) {
            page_type = 'home';
        } else if(cache.$matomoEnabled.find('.blog_single').length) {
            page_type = 'blog_single';
        } else if(cache.$matomoEnabled.find('.info-cataloghi').length || cache.$matomoEnabled.find('.nostri-valori').length) {
            page_type = 'values / info';
        }

        if(page_type) {
            cache.$body.trigger('Tracking:ViewPage', {
                page_type: page_type
            });
        }

    }
}

module.exports = () => {
    $(document).ready(trackingViewPage);
    cache.$newsletterBtn.on('click', showNewsletterDiv);
    cache.$newletterCloseBtn.on('click', hideNewsletterDiv);
    cache.$closeBanner.on('click', hideBannerDownloadApp);
    cache.$guestFormAddress.on('keyup', '.address-form-postalCode', e => verifyZip(e.target));
    cache.$guestFormAddress.on('submit', submitFormHandler);
};

